import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import Lightbox from "react-image-lightbox"

import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles.module.css"
import "react-image-lightbox/style.css"

const ProjectSection = ({ title, description, image, alignment = "left" }) => {
  const [open, setOpen] = useState(false)

  return (
    <section className={`${styles.section} ${styles[alignment]}`}>
      {open && (
        <Lightbox mainSrc={image.src} onCloseRequest={() => setOpen(false)} />
      )}
      <div className={styles.container}>
        <div className={styles.subsection}>
          <h1 className={styles.title}>
            {title}
            <Pontuation>.</Pontuation>
          </h1>
          <ReactMarkdown className={styles.description} source={description} />
        </div>
        <div className={styles.subsection}>
          {image && (
            <img
              src={image.src}
              className={styles.featuredImg}
              alt={title}
              onClick={() => setOpen(true)}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default ProjectSection
