import React from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"
import ProjectCard from "../ProjectCard/ProjectCard"
import ProjectSection from "../ProjectSection/ProjectSection"

import styles from "./styles/project.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SEO from "../seo"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    /* eslint-disable-next-line */
    <FontAwesomeIcon
      className={className}
      onClick={onClick}
      style={{
        color: "#ffffff",
        opacity: 0.6,
        fontSize: 16,
        marginRight: 10,
        ...style,
      }}
      icon={faAngleRight}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    /* eslint-disable-next-line */
    <FontAwesomeIcon
      className={className}
      onClick={onClick}
      style={{
        color: "#ffffff",
        opacity: 0.6,
        fontSize: 16,
        marginRight: 10,
        ...style,
      }}
      icon={faAngleLeft}
    />
  )
}

const Project = ({ project, projectsList, headerImg, page, ...props }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  return (
    <SimpleLayout path={props.path}>
      <SEO
        title={`LeapLane | ${project.title}`}
        description={project.description}
      />
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 className={styles.sectionTitle}>
              {project.title}
              <Pontuation>.</Pontuation>
            </h1>
            <h2 className={styles.sectionDescription}>{project.subtitle}</h2>
          </div>
          <div className={styles.rigth}>
            <img
              src={headerImg.fixed.src}
              className={styles.headerImg}
              alt={project.title}
            />
            {project.featuredImage && (
              <img
                src={project.featuredImage.childImageSharp.fixed.src}
                className={styles.featuredImg}
                alt={project.title}
              />
            )}
          </div>
        </div>
      </header>
      {(project.subProject || []).map((subProject, i) => (
        <ProjectSection
          alignment={i % 2 !== 0 ? "left" : "right"}
          title={subProject.title}
          description={subProject.description}
          image={
            subProject.featuredImage &&
            subProject.featuredImage.childImageSharp.fluid
          }
        />
      ))}

      <section className={styles.moreProjects}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            More projects<Pontuation>.</Pontuation>
          </h1>
          <h2 className={styles.sectionDescription}>{page.subtitle}</h2>
          <div className={styles.cards}>
            <Slider {...sliderSettings}>
              {projectsList.map(({ node }) => (
                <ProjectCard
                  cardStyle={{ width: "100%", margin: "0 0 30px 0" }}
                  key={node.id}
                  slug={node.fields.slug}
                  active={node.frontmatter.title === project.title}
                  icon={node.frontmatter.icon.childImageSharp.fluid}
                  title={node.frontmatter.title}
                  description={node.frontmatter.subtitle}
                />
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

export default Project
